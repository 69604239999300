.splitter-layout {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: auto;
  }
  
  .splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
  }
  
  .splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
  }
  
  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background-color: #ccc;
  }
  
  .splitter-layout .layout-splitter:hover {
    background-color: #bbb;
  }
  
  .splitter-layout.layout-changing {
    cursor: col-resize;
  }
  
  .splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
  }
  
  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }
  
  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }
  
  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: calc(100% - 8px);
    margin-left: 4px;
    height: 3px;
    cursor: row-resize;
    margin-top: 4px;
    margin-bottom: 2px;
    background: linear-gradient(to right, #aaa 45%, #e6e5e5 45% 55%, #aaa 55% 100%);
    -webkit-box-shadow: 0px 0px 2px 2px #646464a6;
    -moz-box-shadow: 0px 0px 2px 2px #646464a6;
    box-shadow: 0px 0px 2px 2px #646464a6;
    border-radius: 8px;
  }

  @media only screen and (max-width: 600px) {
    .splitter-layout.splitter-layout-vertical > .layout-splitter {
      height: 10px;
      background: none;
      background-color: #aaa;
      background-image: url('/images/ui/ui-splitter-horizontal-icon.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: inherit;
    }
  }