.borderRed {
    border: solid 1px red;
}

.fullWidth {
    width: 100% !important;
}

.flexExpander {
    flex-grow: 1;
}

.flexWrap {
    flex-basis: 100%;
    height: 0;
}

.horizontalFlex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.selectable {  
  -webkit-user-select: text !important;  /* Chrome 49+ */
  -moz-user-select: text !important;     /* Firefox 43+ */
  -ms-user-select: text !important;      /* No support yet */
  user-select: text !important;          /* Likely future */   
}

.previewDetailsComponent tr:nth-child(even) {
  background-color: #dddddd;
}