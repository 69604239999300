.center {
    text-align: center;
}
.hairLine {
    height: 1px;
    background-color: rgb(197, 197, 197);
}
.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.heading {
    font-family: sans-serif;
    color: rgb(90, 117, 84);
    font-size: x-large;
    margin: 2%;
}
.label {
    font-family: sans-serif;
    color: rgb(90, 117, 84);
    font-size: medium;
}
.caption {
    font-family: sans-serif;
    color: rgb(88, 88, 88);
    font-size: small;
    text-align: justify;
}
.captionMedium {
    font-family: sans-serif;
    color: rgb(88, 88, 88);
    font-size: medium;
}
.column {
    flex: 1;
}
.error {
    color: red;
}
.errorBg {
    background-color: #ffe0e0;
}
.input {
    width: 100%;
    padding: 10px 10px;
    margin: 8px 0;
    font-size: medium;
    border: 1px solid rgb(202, 202, 202);
}
.inputSubmitted {
    border: none !important;
}
.submitBtn {
    background-color: #7ad98e;
    font-family: sans-serif;
    font-size: large;
    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    text-shadow: 1px 1px #202020;
    -webkit-box-shadow: 2px 4px 4px 1px rgba(173,173,173,1);
    -moz-box-shadow: 2px 4px 4px 1px rgba(173,173,173,1);
    box-shadow: 2px 4px 4px 1px rgba(173,173,173,1);
    cursor: pointer;
}
.brandLogo {
    width: 100%; 
    max-width: 350px;
}
.registrationFormContainerWrapper {
    width: 100%;
    height: 100%;
    min-height: 670px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.registrationFormContainer {
    width: 50%;
    min-width: 340px;
    max-width: 500px;
    display: flex; 
    flex-direction: column;
    margin: 0 auto;
    margin: auto;
    padding: 30px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 3px 3px 3px 1px rgba(173,173,173,1);
    -moz-box-shadow: 3px 3px 3px 1px rgba(173,173,173,1);
    box-shadow: 3px 3px 3px 1px rgba(173,173,173,1);
}
.hidden {
    display: none;
}
@media (max-width:1025px) {
    .smallSceenHidden {
        display: none;
    }
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: small;
}
::-moz-placeholder { /* Firefox 19+ */
    font-size: small;
}
:-ms-input-placeholder { /* IE 10+ */
    font-size: small;
}
:-moz-placeholder { /* Firefox 18- */
    font-size: small;
}
@media (min-width:1025px) and (min-width:1281px) {
    ::-webkit-input-placeholder {
       /* WebKit browsers */
        color: transparent;
   }
    :-moz-placeholder {
       /* Mozilla Firefox 4 to 18 */
        color: transparent;
   }
    ::-moz-placeholder {
       /* Mozilla Firefox 19+ */
        color: transparent;
   }
    :-ms-input-placeholder {
       /* Internet Explorer 10+ */
        color: transparent;
   }
    input::placeholder {
        color: transparent;
   }
    textarea::-webkit-input-placeholder {
       /* WebKit browsers */
        color: transparent;
   }
    textarea:-moz-placeholder {
       /* Mozilla Firefox 4 to 18 */
        color: transparent;
   }
    textarea::-moz-placeholder {
       /* Mozilla Firefox 19+ */
        color: transparent;
   }
    textarea:-ms-input-placeholder {
       /* Internet Explorer 10+ */
        color: transparent;
   }
    textarea::placeholder {
        color: transparent;
   }
}
.lds-roller {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(55, 65, 66);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }